import {Accordion} from "react-bootstrap";
import logo from "../assets/picto.png";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

const NoticeTwo = () => {
    const {t} = useTranslation();
    return (
        <div className='container col-12 col-md-4 ps-5 pe-5 ms-auto me-auto h-100 roboto-regular'>
            <h3 className='mt-3 mb-5 roboto-bold dark-blue'>Notice d'utilisation</h3>
            <Accordion>
                <Accordion.Item eventKey="16">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Introduction</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>Anticiper pour mieux te protéger</strong><br/><br/>
                        <strong>Comment te préparer ?</strong>
                        <ul className='list-unstyled'>
                            <li>1. Active l'application à l'avance</li>
                            <li>2. Garde-la discrètement en premier plan</li>
                            <li>3. Vérifie que tes contacts sont disponibles</li>
                        </ul>
                        <strong>Lis attentivement cette Notice d’utilisation pour bien utiliser l’application.</strong>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Navigation dans l'application</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong><i>1. Écran principal</i></strong><br/>
                        <strong>L'écran affiche trois boutons essentiels :</strong>
                        <ul>
                            <li>Au centre : bouton SOS pour déclencher l'alarme</li>
                            <li>En bas à gauche : bouton Position pour voir ta localisation GPS</li>
                            <li>En bas à droite : bouton Menu pour accéder aux paramètres</li>
                        </ul>
                        <i>1.1 Ma position GPS</i>
                        <ul>
                            <li>Appuie sur le bouton Position pour afficher ta localisation actuelle</li>
                            <li>Utilise l'icône Partager pour envoyer ta position</li>
                        </ul>
                        <i>1.2 menu principal</i><br/>
                        Pour accéder au menu, appuie sur le bouton en bas à droite de l'écran.
                        <br/>
                        Options disponibles :
                        <ul>
                            <li>Mon compte : paramètres personnels et configuration</li>
                            <li>Mes contacts : gestion des contacts d'urgence</li>
                            <li>Mes fonds d'écran : personnalisation de l'affichage</li>
                            <li>Guide pratique : conseils d'utilisation</li>
                            <li>Mentions légales</li>
                            <li>À propos</li>
                            <li>Partage avec tes amis</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Paramétrage du compte</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>1. Mon compte</strong><br/>
                        Accède à tes paramètres personnels depuis le Menu → Mon compte
                        <br/>
                        Identité et Mot de passe
                        <ul>
                            <li>Modifie tes informations personnelles</li>
                            <li>
                                Change ton mot de passe quand nécessaire :
                                <ul>
                                    <li>Clique sur "Changer le mot de passe"</li>
                                    <li>Entre l'ancien mot de passe</li>
                                    <li>Saisis et confirme le nouveau</li>
                                </ul>
                            </li>
                        </ul>
                        <strong>2. Configuration du tracking GPS</strong>
                        <ul>
                            <li>Active/désactive le suivi en temps réel</li>
                            <li>Choisis la durée du tracking :
                                <ul>
                                    <li>30 minutes</li>
                                    <li>1 heure</li>
                                    <li>3 heures</li>
                                </ul>
                            </li>
                            <li>Sans tracking : seule ta position initiale sera partagée</li>
                        </ul>
                        <strong>3. Affichage permanent</strong><br/>
                        <ul>
                            <li>Sélectionne la durée d'affichage en premier plan :
                                <ul>
                                    <li>prédéfinies : 30mn, 1h, 3h</li>
                                    <li>personnalisée : de 5mn à 12h</li>
                                </ul>
                            </li>
                        </ul>
                        <strong>Gestion des SMS</strong><br/>
                        <ul>
                            <li>Consulte ton solde d'alarmes</li>
                            <li>Recharge ton compte :
                                <ul>
                                    <li>Choisis un pack</li>
                                    <li>Procède au paiement</li>
                                    <li>Le solde est mis à jour instantanément</li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Gestion des contacts</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Accède à la gestion des contacts depuis le Menu → Mes contacts
                        <br/>
                        <strong>1. Configuration du contact prioritaire (n°1)</strong>
                        <ul>
                            <li>Option A - Service d'urgence national :
                                <ul>
                                    <li>Sélectionne ton pays dans la liste</li>
                                    <li>Le numéro d'urgence s'affiche automatiquement</li>
                                    <li>En cas d'alerte : trois clics nécessaires pour l'appel</li>
                                </ul>
                            </li>
                            <li>
                                Option B - Contact personnel :
                                <ul>
                                    <li>
                                        Saisis le nom, l’email et le numéro de téléphone
                                    </li>
                                    <li>En cas d'alerte :
                                        <ul>
                                            <li>Deux clics : envoi du SMS</li>
                                            <li>Troisième clic : appel vocal</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <strong>2. Ajout des contacts supplémentaires</strong>
                        <ul>
                            <li>Clique sur "Ajouter un contact"</li>
                            <li>Remplis les champs :
                                <ul>
                                    <li>Nom et prénom</li>
                                    <li>Numéro de téléphone</li>
                                </ul>
                            </li>
                            <li>Valide pour enregistrer</li>
                        </ul>
                        <strong>3. Modification/Suppression</strong>
                        <ul>
                            <li>Sélectionne le contact à modifier</li>
                            <li>Utilise les boutons :
                                <ul>
                                    <li>"Modifier" pour changer les informations</li>
                                    <li>"Supprimer" pour retirer le contact</li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Personnalisation de l'affichage</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        Accède aux options d'affichage depuis le Menu → Mes fonds d'écran
                        <br/>
                        <strong>1. Choix du fond d'écran :</strong>
                        <ul>
                            <li>Depuis la collection :
                                <ul>
                                    <li>Parcours les thèmes disponibles</li>
                                    <li>Clique sur l'image choisie</li>
                                    <li>Valide ta sélection</li>
                                </ul>
                            </li>
                            <li>
                                Import personnel :
                                <ul>
                                    <li>
                                        Sélectionne "Importer une image"
                                    </li>
                                    <li>
                                        Choisis une photo de ta galerie
                                    </li>
                                    <li>
                                        Ajuste le cadrage si nécessaire
                                    </li>
                                    <li>
                                        Valide
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <strong>2. Réglage de la transparence</strong>
                        <ul>
                            <li>Utilise le curseur pour ajuster (0-100%)</li>
                            <li>Visualise le résultat en temps réel</li>
                            <li>La transparence s'applique :
                                <ul>
                                    <li>A ton fond d'écran</li>
                                    <li>Aux 3 boutons</li>
                                </ul>
                            </li>
                        </ul>
                        <strong>3. Contrôle de l'affichage</strong>
                        <ul>
                            <li>Programme la durée d'affichage</li>
                            <li>L'application restera visible en premier plan</li>
                            <li>Une notification sur l’icône de l’application te rappelle de réactiver si nécessaire</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                    <Accordion.Header>
                                    <span>
                                        <strong className='roboto-bold'>Fonctionnement des alertes</strong>
                                    </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>1. Déclenchement d'une alerte</strong>
                        <ul>
                            <li>Double-clic sur le bouton SOS
                                <ul>
                                    <li>Envoie les SMS à tous tes contacts</li>
                                    <li>Les SMS incluent ta position GPS</li>
                                </ul>
                            </li>
                            <li>
                                Troisième clic (si nécessaire) :
                                <ul>
                                    <li>
                                        Obligatoire vers le service d'urgence national
                                    </li>
                                    <li>
                                        Déclenche l'appel vocal vers le contact prioritaire
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <strong>2. Suivi GPS</strong><br/>
                        Si tracking activé :
                        <ul>
                            <li>Le suivi commence dès l'envoi des SMS</li>
                            <li>Tes contacts peuvent suivre tes déplacements</li>
                            <li>Le tracking s'arrête automatiquement après la durée choisie</li>
                        </ul>
                        Sans tracking  :
                        <ul>
                            <li>Seule ta position initiale est partagée</li>
                            <li>Position actualisée à chaque nouvel envoi de SMS</li>
                        </ul>
                        Partage de position manuel  :
                        <ul>
                            <li>Clique sur le bouton Position</li>
                            <li>Visualise ta localisation sur la carte</li>
                            <li>Utilise l'icône Partager pour envoyer ta position</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <Link to='/' className="btn btn-outline-dark outline-grey shadow-lg w-100">{t('buttons.close')}</Link>
            </div>
        </div>
    )
}

export default NoticeTwo;