import React, {useEffect, useState} from "react";
import logo from "../assets/logo-blanc-1.png";
import {useAddToHomescreenPrompt} from "../hooks/InstallPrompt";
import {useNavigate} from "react-router-dom";
import AccountCreation from "../components/AccountCreation";
import UserSetup from "../components/UserSetup";
import {useTranslation} from "react-i18next";

export default function Install() {
    const {t} = useTranslation();
    const [steps, setSteps] = useState(0);
    const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod|mac/.test(userAgent);
    }

    const navigate = useNavigate();

    useEffect(() => {
        const first = localStorage.getItem('first_launch');
        if (first !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            {steps === 0 && <UserSetup setSteps={setSteps}/>}
            {steps === 1 && <AccountCreation setSteps={setSteps}/>}
        </>
    )
}