import {Accordion} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {t} from "i18next";

const LegalMentions = () => {
    const [user] = useState(JSON.parse(localStorage.getItem('user') || '[]'))

    return (
        <div className='container col-12 col-md-4 ms-auto me-auto h-100 mt-5 ps-5 pe-5'>
            <Accordion className='mb-5'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <span className='roboto-bold'>Conditions d'utilisation</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <strong>1. Préambule</strong><br/>
                        <p>
                            1.1 Les conditions générales suivantes sont conclues entre vous et Active Business
                            Développement, société à responsabilité limitée au capital de 30 000 euros, enregistrée à
                            Antibes sous le numéro RCS B 504 472 481 et dont l'adresse enregistrée est située au 92
                            boulevard Président Wilson, 06160 Juan Les Pins. France, et constituent un accord juridique
                            qui régit votre utilisation de la plateforme Active Business, que vous y accédiez via notre
                            site web (www.Active Business, le « site web ») ou une application mobile, et tout contenu
                            mis à votre disposition via cette plateforme (collectivement, le « service »).
                        </p>
                        <p>
                            1.2 Notre service permet, via notre web-application mobile URGENT Assistance, de déclencher
                            une alarme par commande tactile et d’envoyer un sms indiquant la géolocalisation GPS vers le
                            ou les contacts d’urgence préalablement désignés, leur permettant ainsi de localiser et
                            porter secours ou assistance à l’utilisateur ou appeler les services d’urgence du pays où se
                            trouve l’utilisateur.
                        </p>
                        <p>
                            1.3 Nous exploitons le Service sous une licence Open Database. Les conditions générales
                            d'utilisation de la licence OpenStreetMap sont disponibles sur
                            http://www.openstreetmap.org/copyright/en.
                        </p>
                        <p>
                            1.4 Dans les présentes conditions, Active Business est désignée par « nous », « notre » ou «
                            nos ». Nous utilisons les termes « vous » et « votre » pour désigner toute personne visitant
                            ou utilisant le site web ou l’application URGENT Assistance, ainsi que toute organisation ou
                            personne visitant ou utilisant le site web au nom d'une organisation.
                        </p>
                        <strong>2. À propos des conditions d'utilisation</strong>
                        <p>
                            2.1 En visitant ou en utilisant le Service, vous acceptez les présentes conditions
                            d'utilisation (les « Conditions d'utilisation »). Veuillez vous assurer de lire
                            attentivement ces Conditions d'utilisation. Si vous n'acceptez pas les Conditions
                            d'utilisation, veuillez ne pas continuer à utiliser le Service.
                        </p>
                        <p>
                            2.2 Nous pouvons mettre à jour ces Conditions d'utilisation de temps à autre. Si nous le
                            faisons, les nouvelles conditions entreront en vigueur à partir du moment où elles seront
                            téléchargées sur le site Web. Vous devez vérifier les Conditions d'utilisation lorsque vous
                            visitez le site web pour confirmer les Conditions d'utilisation les plus récentes qui
                            s'appliquent. Si vous n'acceptez pas les conditions d'utilisation modifiées, vous devez
                            cesser d'utiliser le service et le site web.
                        </p>
                        <strong>
                            3. Aucune responsabilité pour les autres sites web, le contenu généré par les utilisateurs
                            ou les publications sur les réseaux sociaux
                        </strong>
                        <p>
                            3.1 Parfois, le service peut créer des liens vers d'autres sites Web que nous ne possédons
                            ni ne contrôlons. Ces liens sont uniquement pour votre commodité ou votre information, et
                            peuvent être soumis à leurs propres conditions générales. Nous ne pouvons pas vérifier
                            l'exactitude des informations contenues dans les sites Web tiers, et l'inclusion de ces
                            liens ne représente pas une approbation ou une recommandation des sites Web tiers. Nous
                            n'acceptons aucune responsabilité pour le contenu de tout site web auquel le site web
                            renvoie. Nous vous suggérons de lire les conditions et la politique de confidentialité de
                            chaque site web tiers et service en ligne que vous visitez lorsque vous quittez le service.
                        </p>
                        <p>
                            3.2 Nous exploitons des canaux, des pages et des comptes sur certains réseaux sociaux,
                            auxquels vous pouvez accéder via des liens via le service. Nous ne sommes pas responsables
                            des informations publiées sur ces sites de réseaux sociaux autres que celles que nous avons
                            publiées nous-mêmes. Nous n'approuvons pas les sites de réseaux sociaux eux-mêmes, ni les
                            informations publiées sur eux par des tiers ou d'autres utilisateurs.
                        </p>
                        <p>
                            3.3 Le Service fournit la fonctionnalité permettant de créer et/ou de télécharger du contenu
                            sur le Service (« Contenu généré par l'utilisateur »). Vous reconnaissez que nous n'avons
                            aucun contrôle sur le contenu ou les informations téléchargés par les utilisateurs du
                            Service et, bien que nous puissions prendre, à notre entière discrétion, des mesures pour
                            modérer ou organiser le contenu généré par l'utilisateur à notre entière discrétion, nous ne
                            donnons aucune garantie que nous le ferons et, par conséquent, vous reconnaissez et acceptez
                            que nous ne sommes pas responsables du Contenu généré par l'utilisateur que vous téléchargez
                            sur le Service, ou du contenu généré par l'utilisateur que d'autres utilisateurs peuvent
                            télécharger sur le Service. Nous vous rappelons vos obligations en vertu du paragraphe
                            5.6(D) ci-dessous concernant la création et le téléchargement de Contenu généré par
                            l'utilisateur.
                        </p>
                        <strong>4. Lien vers le Service</strong>
                        <p>
                            4.1 Vous pouvez créer un lien vers la page d'accueil du site web à partir de votre site Web,
                            à condition que cela soit fait d'une manière à la fois juste et légale, cela inclut, mais
                            sans s'y limiter, le partage de contenu sur les réseaux sociaux.
                        </p>
                        <p>
                            4.2 Vous ne devez pas créer de lien vers le Service si cela peut nuire à notre réputation ou
                            si vous suggérez une forme d'association, d'approbation ou de soutien de notre part alors
                            qu'il n'en existe aucune.
                        </p>
                        <strong>
                            5. Votre utilisation du Service
                        </strong>
                        <p>
                            5.1 À condition que vous vous conformiez aux présentes Conditions d'utilisation, nous vous
                            accordons une licence non exclusive, non transférable, non sous-licenciable, limitée et
                            révocable pour utiliser le Service uniquement à des fins personnelles et non commerciales.
                        </p>
                        <p>
                            5.2 Si vous avez moins de 18 ans, vous devez avoir le consentement de vos parents ou de
                            votre tuteur légal pour utiliser le Service.
                        </p>
                        <p>
                            5.3 Afin d'accéder à certaines parties du Service et de les utiliser, y compris les Services
                            supplémentaires tels que définis ci-dessous), vous devrez peut-être créer un compte
                            utilisateur chez nous. Pour créer un compte chez nous, vous devez (i) avoir 18 ans ou plus ;
                            ou (ii) si vous avez moins de 18 ans, avoir l'autorisation de votre parent ou tuteur légal
                            pour utiliser le Service et votre parent ou tuteur légal doit accepter d'être lié par les
                            présentes Conditions d'utilisation.
                        </p>
                        <p>
                            5.4 Si vous créez un compte chez nous:
                            <ul>
                                <li>
                                    vous devez vous inscrire en utilisant des informations correctes et à jour et vous
                                    acceptez que :
                                </li>
                                <li>
                                    il est de votre responsabilité de vous assurer que ces informations, y compris vos
                                    coordonnées, restent à jour et exactes afin que nous puissions communiquer et
                                    effectuer des transactions avec vous ;
                                </li>
                                <li>
                                    si vous ne recevez pas de notification ou de communication de notre part parce que
                                    vos coordonnées sont incorrectes ou obsolètes, nous serons réputés vous avoir
                                    notifié ;
                                </li>
                                <li>
                                    vous reconnaissez avoir lu, compris et accepté tous les termes et conditions
                                    contenus dans les présentes Conditions d'utilisation, ainsi que notre Politique de
                                    confidentialité ;
                                </li>
                                <li>
                                    vous êtes responsable de la préservation de la confidentialité de votre mot de passe
                                    (par exemple en ne le partageant pas avec d'autres) et des autres informations de
                                    compte que vous utilisez pour accéder à votre compte sur le Service ;
                                </li>
                                <li>
                                    vous nous informerez immédiatement de toute utilisation non autorisée connue ou
                                    suspectée de votre compte ; et
                                </li>
                                <li>
                                    vous serez responsable des actes ou omissions de tout tiers qui accède ou utilise
                                    votre compte ou le Service en votre nom, lorsque cette utilisation est autorisée par
                                    vous ou en raison de votre négligence (par exemple en ne sécurisant pas les
                                    informations d'identification de votre compte ou en partageant vos informations
                                    d'identification de compte en violation des présentes Conditions d'utilisation).
                                </li>
                            </ul>
                        </p>
                        <p>
                            5.5 Lorsque vous visitez ou utilisez le Service :
                        </p>
                        <p>
                            vous acceptez de ne pas : (i) tenter de mettre à disposition de tiers des données via les
                            Services en masse, (ii) interroger systématiquement les données disponibles via les Services
                            pour obtenir la totalité ou la quasi-totalité des données pour un emplacement donné, ou
                            (iii) désassembler, procéder à une ingénierie inverse ou décompiler (sauf dans la mesure
                            limitée expressément autorisée par la loi statutaire applicable), modifier, explorer (ou
                            faciliter l'exploration) ou altérer toute partie des données disponibles via les Services ;
                        </p>
                        <p>
                            vous ne devez pas introduire, transmettre, télécharger ou envoyer de virus, chevaux de
                            Troie, logiciels espions, logiciels publicitaires, vers, enregistreurs de frappe, autres
                            logiciels malveillants ou autres codes informatiques nuisibles conçus pour nuire au
                            fonctionnement de tout matériel informatique ou logiciel ;
                        </p>
                        <p>
                            vous ne devez pas faire ou autoriser quoi que ce soit qui bloque, désactive, remplace ou
                            interfère avec le fonctionnement du Service (y compris tout élément fourni par nos
                            fournisseurs tiers) ;
                        </p>
                        <p>
                            vous acceptez de ne pas vous faire passer pour une personne ou une entité, d'utiliser un nom
                            fictif ou de dénaturer votre affiliation avec une personne ou une entité ; et
                        </p>
                        <p>
                            vous acceptez d'utiliser le Service en conformité avec toutes les lois applicables.
                        </p>
                        <p>
                            5.6 Vous reconnaissez et acceptez que :
                        </p>
                        <ul>
                            <li>
                                le Service n'a pas été développé pour répondre à vos besoins individuels ;
                            </li>
                            <li>
                                dans la mesure permise par les lois et réglementations applicables, tout logiciel ou
                                autre fonctionnalité que nous fournissons ou que l'un de nos fournisseurs tiers fournit
                                dans le cadre du Service sera fourni « tel quel » ;
                            </li>
                            <li>
                                tout système ou appareil que vous pouvez utiliser pour accéder au Service est hors de
                                notre contrôle et nous ne serons pas responsables du fonctionnement ou de la sécurité de
                                ces appareils ou systèmes ;
                            </li>
                            <li>
                                tout contenu généré par l'utilisateur ne doit pas :
                                <ul>
                                    <li>
                                        être diffamatoire envers une personne, obscène, offensant, haineux et/ou
                                        provocateur ;
                                    </li>
                                    <li>
                                        promouvoir ou inclure tout matériel sexuellement explicite, matériel d'abus
                                        sexuel d'enfants, violence, discrimination ou autre contenu ou activité illégale
                                        ;
                                    </li>
                                    <li>
                                        enfreindre tout droit de propriété intellectuelle de toute autre personne (y
                                        compris, mais sans s'y limiter, le droit d'auteur, le droit de base de données
                                        ou la marque commerciale) ;
                                    </li>
                                    <li>
                                        enfreindre toute obligation légale envers un tiers, telle qu'une obligation
                                        contractuelle ou une obligation de confidentialité ;
                                    </li>
                                    <li>
                                        préconiser, promouvoir, inciter toute partie à commettre ou aider tout acte
                                        illégal ou criminel tel que (à titre d'exemple uniquement) la violation du droit
                                        d'auteur ou l'utilisation abusive d'un ordinateur ; et
                                    </li>
                                    <li>
                                        contenir toute publicité ou promouvoir tout service ou lien Web vers d'autres
                                        sites ;
                                    </li>
                                </ul>
                            </li>
                            <li>
                                les systèmes d'information et de télécommunication ne sont pas exempts d'erreurs ; et
                            </li>
                            <li>
                                la fourniture de tout service basé sur Internet peut être sujette à des interruptions.
                            </li>
                        </ul>
                        <p>
                            5.7 Vous ne pouvez pas accéder au Service ou l'utiliser :
                        </p>
                        <ul>
                            <li>
                                au nom d'une agence gouvernementale, d'un département ou d'une entité à tout niveau de
                                gouvernement (par exemple, national, étatique, municipal, etc.) (« Utilisateurs non
                                gouvernementaux américains ») si une telle utilisation du Service nous imposerait, en
                                vertu de la loi ou du contrat, des obligations ou des droits sur notre Service autres
                                que ceux décrits dans les présentes Conditions d'utilisation. Vous nous indemniserez et
                                nous dégagerez de toute responsabilité en cas de réclamation, responsabilité, dommage,
                                perte ou coût (y compris les honoraires d'avocat raisonnables) fondés sur ou découlant
                                de votre non-respect de cette clause ; ou
                            </li>
                            <li>
                                si vous êtes soumis à, ou pourriez amener nous ou l'un de nos partenaires/fournisseurs
                                tiers à enfreindre, toute loi et réglementation en matière de contrôle des exportations
                                et de sanctions des États-Unis d'Amérique, des États membres de l'Union européenne et de
                                tout autre pays concerné.
                            </li>
                        </ul>
                        <strong>
                            6. Services supplémentaires
                        </strong>
                        <p>
                            6.1 Dans le cadre du Service, nous pouvons proposer des services ou fonctions
                            supplémentaires fournis par nous ou par l'un de nos partenaires tiers (« Services
                            supplémentaires »).
                        </p>
                        <p>
                            6.2 Afin d'utiliser ces Services supplémentaires, vous devrez accepter des conditions
                            générales supplémentaires qui régissent l'utilisation de ces Services supplémentaires. Ces
                            conditions générales indiqueront si ces conditions générales s'ajoutent aux présentes
                            conditions générales (ce qui sera le cas si elles concernent des Services supplémentaires
                            fournis par nous), ou s'il s'agit de conditions autonomes pour des Services supplémentaires
                            fournis par un tiers. Vous pouvez également être facturé pour ces Services supplémentaires.
                        </p>
                        <p>
                            6.3 Lorsqu'ils sont fournis par un tiers, nous n'acceptons aucune responsabilité à l'égard
                            des Services supplémentaires et tout problème que vous pourriez rencontrer doit être réglé
                            auprès du propriétaire ou du fournisseur de ces Services supplémentaires.
                        </p>
                        <p>
                            6.4 Vous pouvez être tenu de fournir un moyen de paiement afin de payer et de recevoir un
                            Service supplémentaire. En fournissant un mode de paiement que nous acceptons, vous déclarez
                            et garantissez que vous êtes autorisé à utiliser le mode de paiement désigné et que vous
                            nous autorisez (ou le tiers concerné) à facturer votre mode de paiement pour le montant
                            total des frais correspondants que vous êtes tenu de payer (y compris les taxes applicables
                            et autres frais supplémentaires que vous avez acceptés lors de la passation d'une commande
                            pour le Service supplémentaire). Si le mode de paiement ne peut pas être vérifié, est
                            invalide ou n'est pas acceptable pour une autre raison, votre commande pour le Service
                            supplémentaire peut être suspendue ou annulée. Vous pouvez modifier ou mettre à jour les
                            informations de paiement associées à votre compte, à tout moment en vous connectant à votre
                            compte.
                        </p>
                        <strong>
                            7. Confidentialité des données
                        </strong>
                        <p>
                            Outre les présentes Conditions d'utilisation, d'autres conditions peuvent s'appliquer à
                            vous. Il s'agit notamment de notre Politique de confidentialité, qui couvre également les
                            cookies et peut être consultée ici.
                        </p>
                        <strong>
                            8. Nos droits de propriété intellectuelle
                        </strong>
                        <p>
                            8.1 Nous sommes le propriétaire ou le titulaire de licence de tous les droits de propriété
                            intellectuelle sur le Service et le Site web et le matériel publié sur ceux-ci, y compris,
                            entre autres, toutes les marques commerciales, logos, marques de service et noms de service,
                            qu'ils soient enregistrés ou non. Ces œuvres sont protégées, entre autres, par les lois et
                            traités sur le droit d'auteur dans le monde entier. Nous nous réservons tous ces droits.
                        </p>
                        <p>
                            8.2 Vous nous accordez une licence exclusive, perpétuelle, libre de redevances, irrévocable,
                            transférable, mondiale et illimitée (avec le droit de sous-licencier celle-ci) pour utiliser
                            tout Contenu généré par l'utilisateur, toutes les informations que vous nous fournissez lors
                            de l'utilisation du Service (y compris toutes les données) et toutes les informations
                            relatives à vos activités sur le Service.
                        </p>
                        <p>
                            8.3 Sauf mention expresse dans les présentes Conditions d'utilisation ou si nous vous
                            donnons une autorisation écrite pour le faire, vous ne pouvez pas copier, reproduire,
                            rééditer, désassembler, décompiler, faire de l'ingénierie inverse, télécharger, publier,
                            diffuser, transmettre, mettre à disposition du public ou utiliser de toute autre manière le
                            Service (y compris, mais sans s'y limiter, tout contenu (qu'il soit le nôtre ou celui d'un
                            autre utilisateur), le Site web ou toute application mobile) de quelque manière que ce soit,
                            sauf pour votre propre usage personnel et non commercial. Vous acceptez également de ne pas
                            adapter, modifier ou créer une œuvre dérivée à partir de tout contenu du Service, sauf pour
                            votre propre usage personnel et non commercial.
                        </p>
                        <strong>
                            9. Responsabilité
                        </strong>
                        <p>
                            9.1 Les présentes Conditions d'utilisation excluent ou limitent notre responsabilité
                            découlant de l'utilisation du Service ou en relation avec celui-ci dans la mesure où la loi
                            nous le permet. Nous n'excluons ni ne limitons notre responsabilité en cas de décès ou de
                            blessure corporelle résultant de notre négligence, ou de notre fraude ou de fausse
                            déclaration frauduleuse, ou de toute autre responsabilité qui ne peut être légalement exclue
                            ou limitée en vertu des lois de l'Angleterre et du Pays de Galles.
                        </p>
                        <p>
                            9.2 Bien que nous prenions des mesures raisonnables pour garantir que les informations sur
                            le Service ou obtenues via celui-ci sont exactes et à jour, nous ne donnons ni ne faisons
                            aucune garantie ou déclaration (expresse ou implicite) que ces informations sont exactes,
                            opportunes, complètes ou disponibles. Nous ne serons pas responsables envers vous de toute
                            perte ou dommage résultant de votre utilisation ou de la confiance que vous accordez aux
                            informations sur le Service ou obtenues via celui-ci, ou de votre incapacité à accéder au
                            Service ou à l'utiliser pendant une période donnée.
                        </p>
                        <p>
                            9.3 Bien que nous prenions également des mesures raisonnables pour garantir que le Service
                            soit disponible 24 heures sur 24 et 7 jours sur 7, nous n'offrons aucune garantie que le
                            Service, ou une partie de celui-ci, sera disponible à tout moment et déclinons expressément
                            toute responsabilité pour toute perte ou tout dommage que vous pourriez subir en raison de
                            l'indisponibilité du Service, ou d'une partie de celui-ci, à tout moment. Le Service, et
                            toutes les données qu'il contient, sont donc fournis « tels quels » et « tels que
                            disponibles ».
                        </p>
                        <p>
                            9.4 Bien que nous nous efforcions de garantir que le Service est exempt de tout virus ou
                            autre contenu malveillant ou nuisible, nous déclinons toute responsabilité pour les erreurs,
                            retards, pertes de données ou dommages causés à votre ordinateur ou appareil résultant de
                            l'utilisation du Service. Il est de votre responsabilité de vous assurer que vous disposez
                            d'un logiciel antivirus à jour ou de tout autre équipement nécessaire pour utiliser le
                            Service en toute sécurité et pour éviter tout ce qui pourrait endommager ou nuire à votre
                            ordinateur ou appareil.
                        </p>
                        <strong>
                            10. Accès au Service
                        </strong>
                        <p>
                            10.1 Nous pouvons avoir des raisons de mettre à jour le Service de temps à autre, bien que
                            nous ne soyons pas tenus de le faire. Nous pouvons mettre à jour ou supprimer des
                            informations du Service pour des raisons commerciales, par exemple si un logiciel pertinent
                            utilisé par le Service pour fonctionner est en cours de mise à jour, ou si les informations
                            ne sont plus pertinentes.
                        </p>
                        <p>
                            10.2 De temps à autre, nous pouvons restreindre ou suspendre l'accès à certaines parties du
                            Service, ou à l'ensemble du Service, par exemple à des fins de maintenance. En tant que tel,
                            l'accès au site web n'est pas nécessairement permanent ou garanti et nous ne serons pas
                            responsables d'une telle restriction ou suspension du Service.
                        </p>
                        <strong>
                            11. Nous contacter
                        </strong>
                        <p>
                            11.1 Lorsque vous nous contactez via info@active-business.fr vous nous fournissez vos
                            coordonnées. Nous traiterons ces données conformément à notre politique de confidentialité.
                        </p>
                        <strong>
                            12. Durée et résiliation
                        </strong>
                        <p>
                            12.1 Ces conditions d'utilisation s'appliqueront à vous dès que vous accéderez au Service et
                            resteront en vigueur jusqu'à leur résiliation ou si vous n'interagissez plus avec nous ou le
                            Service.
                        </p>
                        <p>
                            12.2 Nous pouvons résilier votre droit d'utiliser les fonctions du Service ou votre compte à
                            tout moment, y compris si vous violez ou enfreignez les présentes Conditions d'utilisation
                            ou si le fait de vous permettre d'accéder et d'utiliser le Service ou ses fonctions
                            associées violerait les lois, règles et réglementations applicables, ou nous exposerait à
                            une quelconque responsabilité légale.
                        </p>
                        <p>
                            12.3 Si les Conditions d'utilisation sont résiliées ou si vous n'interagissez plus avec
                            nous, les présentes Conditions d'utilisation peuvent ne plus s'appliquer. Cependant, toutes
                            les dispositions des Conditions d'utilisation qui, de par leur nature, devraient continuer à
                            s'appliquer après la résiliation des présentes Conditions d'utilisation continueront de
                            s'appliquer. Cela comprend, sans limitation, toutes les limitations de responsabilité, les
                            choix de loi et de forum judiciaire et les protections et licences de propriété
                            intellectuelle.
                        </p>
                        <p>
                            13. Loi applicable et juridiction
                        </p>
                        <p>
                            13.1 Les présentes Conditions d'utilisation, le site web et toute information qu'il contient
                            ou obtenue à partir de celui-ci seront régis par les lois de France, et les tribunaux
                            français auront une compétence exclusive pour entendre tout litige découlant de celles-ci.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <span className='roboto-bold'>
                            Politique de confidentialité
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Cette politique de confidentialité explique comment sont utilisées les informations
                            personnelles collectées ou générées par Active Business en relation avec le service URGENT
                            Assistance, que ce soit via notre site web (urgent-assistance.com) ou l'application URGENT
                            Assistance.
                        </p>
                        <strong>
                            Sommaire
                        </strong>
                        <ul className='list-unstyled'>
                            <li>1. Produits et services</li>
                            <li>2. Types de données personnelles collectées</li>
                            <li>3. Utilisations des informations</li>
                            <li>4. Divulgation des informations à des tiers</li>
                            <li>5. Transferts internationaux de données personnelles</li>
                            <li>6. Protections des informations</li>
                            <li>7. Conservations des données personnelles</li>
                            <li>8. Droits et obligations</li>
                            <li>9. Cookies</li>
                            <li>10. Marketing</li>
                            <li>11. Confidentialité des enfants</li>
                            <li>12. Représentant désigné</li>
                            <li>13. Questions et préoccupations</li>
                        </ul>
                        <strong>
                            1. Contexte
                        </strong>
                        <p>
                            1.1 Active Business, société à responsabilité limitée au capital de 30 000 euros,
                            enregistrée à Antibes sous le numéro RCS B 504 472 481 dont le siège social est situé au 92
                            boulevard Président Wilson, 06160 Juan Les Pins. France et d'autres sociétés du groupe
                            Active Business (« Active Business ») collectent et utilisent certaines Données personnelles
                            en relation avec l’utilisateur.
                        </p>
                        <p>
                            1.2 Active Business s’assure qu'elle utilise ces données personnelles conformément aux lois
                            sur la protection des données, y compris le règlement général sur la protection des données
                            (UE) 2016/679 (« RGPD ») et toute autre législation nationale, de mise en œuvre ou de
                            complément sur la protection des données.
                        </p>
                        <p>
                            1.3 Active Business respecte la vie privée de ses utilisateurs et s’engage à protéger toutes
                            les données personnelles. La présente politique de confidentialité régit le traitement des
                            données personnelles par Active Business dans le cadre de l'exercice de ses activités
                            commerciales.
                        </p>
                        <p>
                            1.4 Définitions dans la présente politique de confidentialité :<br/>
                            « Cookie » désigne un petit fichier texte qui comprend une petite quantité d'informations
                            envoyées au navigateur des utilisateurs du site Web, par un serveur Web, et stockées sur le
                            disque dur d'un ordinateur à des fins d'archivage, de collecte de données de navigation à
                            des fins d'analyse statistique et d'offre aux utilisateurs de services liés à leurs intérêts
                            ou à leur localisation.<br/>
                            « Utilisateur(s) » ou « vous » désigne les utilisateurs du site web urgent-assistance.com et
                            de l'application URGENT Assistance.
                            « Application URGENT Assistance » désigne l'application mobile utilisée pour accéder au
                            Service.<br/>
                            « Service URGENT Assistance » ou le « Service » désigne le service de demande de secours par
                            SMS avec géolocalisation GPS URGENT Assistance et les offres associées, y compris
                            l'application URGENT Assistance ou le site web URGENT Assistance.com.<br/>
                            « Site web URGENT Assistance.com » désigne le site web urgent-assistance.com.<br/>
                            « Données personnelles » désigne toutes les données relatives à une personne qui peut être
                            identifiée à partir de ces données ou à partir de ces données et d'autres informations, en
                            possession de Active Business (ou de ses représentants) ou susceptibles d'entrer en
                            possession de Active Business.<br/>
                            « Politique de confidentialité » désigne le présent Politique de confidentialité de Active
                            Business.
                        </p>
                        <strong>
                            2. Les services fournis
                        </strong>
                        <p>
                            La présente Politique de confidentialité concerne les informations suivantes collectées
                            lorsque le Service est fourni :
                            <ul>
                                <li>
                                    les informations reçu via le site web URGENT Assistance et l’application URGENT
                                    Assistance
                                </li>
                                <li>
                                    les informations reçu de tiers ou de nos sociétés affiliées dans le cadre de la
                                    fourniture du Service.
                                </li>
                            </ul>
                        </p>
                        <strong>
                            3. Les types de données personnelles collectées
                        </strong>
                        <p>
                            3.1 Le Service proposé par Active Business nécessite que soient obtenues des Données
                            personnelles concernant l’utilisateur afin de fournir le Service. Les utilisateurs peuvent
                            ne pas être en mesure d'accéder au Service, au site web URGENT Assistance et à l’application
                            URGENT Assistance et de les utiliser si ces Données personnelles ne sont pas fournies. En ce
                            qui concerne chacun des services décrits à la section<br/>
                            3.1 ci-dessus, les Données personnelles suivantes vous concernant sont collectées et
                            traitées :
                            <ul>
                                <li>Les informations fournies à Active Business. Cela comprend les informations fournies
                                    vous concernant. La nature des services demandés détermine le type de données
                                    personnelles demandées, bien que ces informations puissent inclure (à titre de liste
                                    non exhaustive) :
                                    <ul>
                                        <li>
                                            des données personnelles de base telles que le prénom, le nom, le titre, le
                                            nom d'utilisateur ou un identifiant similaire, le mot de passe, la date de
                                            naissance
                                        </li>
                                        <li>
                                            des coordonnées telles que l'adresse de facturation, l'adresse
                                            résidentielle, l'adresse e-mail et les numéros de téléphone
                                        </li>
                                        <li>
                                            des informations de paiement telles que les détails de la carte de
                                            crédit/débit
                                        </li>
                                        <li>
                                            si nécessaire, les informations requises pour effectuer des vérifications de
                                            « connaissance du client » ou pour confirmer son identité
                                        </li>
                                        <li>
                                            toutes informations choisies de partager avec Active Business en utilisant
                                            le Service et qui peuvent être considérées comme des données personnelles.
                                            Cela peut inclure les préférences, les commentaires et les réponses à des
                                            enquêtes
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Les informations collectées ou générées. Cela comprend à titre de liste non
                                    exhaustive :
                                    <ul>
                                        <li>
                                            des informations relatives à l’appareil, telles que l'adresse IP de
                                            l’appareil, le site web de référence, les pages visitées et l'heure de
                                            visite de notre site web et d’utilisation de l’application URGENT Assistance
                                        </li>
                                        <li>
                                            des données de localisation
                                        </li>
                                        <li>
                                            des informations collectées par des cookies et d'autres technologies
                                            similaires
                                        </li>
                                        <li>
                                            toute information concernant les services supplémentaires et interactions
                                            avec l’utilisateur
                                        </li>
                                        <li>
                                            un fichier avec l’historique de contact à utiliser à des fins de demande de
                                            renseignements
                                        </li>
                                        <li>
                                            des données techniques, notamment les données de connexion, le type et la
                                            version du navigateur, le réglage et la localisation du fuseau horaire, les
                                            types et versions de plug-in de navigateur, le système d'exploitation et la
                                            plateforme et d'autres technologies sur les appareils utilisés pour accéder
                                            aux services
                                        </li>
                                        <li>
                                            via nos services de sécurité cloud, des rapports de trafic et de sécurité
                                            qui incluent des informations sur l'utilisation d'Internet par les
                                            utilisateurs d'ordinateurs de l'organisation par exemple, les sites Web
                                            visités par chaque utilisateur, les documents téléchargés, les incidents de
                                            sécurité, les mesures de prévention prises par la passerelle, etc.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Les informations obtenues d'autres sources. Cela comprend les données personnelles
                                    fournies par des fournisseurs de services tiers, des agences ou d'autres sources
                                    accessibles au public, le cas échéant.
                                    <ul>
                                        <li>
                                            Les informations collectées auprès de fournisseurs tiers telles que les
                                            interactions sur les réseaux sociaux ou les informations collectées auprès
                                            des entreprises utilisées pour fournir notre service
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Les cookies.
                                    <ul>
                                        <li>
                                            Lors d’une visite sur le site web URGENT Assistance, des cookies sont
                                            utilisés pour collecter des informations techniques sur les services
                                            utilisés et sur la manière dont ils sont utilisés.
                                        </li>
                                        <li>
                                            Pour plus d'informations sur les cookies utilisés par Active Business,
                                            veuillez consulter la section 10 de la présente politique de
                                            confidentialité.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Données anonymisées
                                    <ul>
                                        <li>
                                            En plus des catégories de données personnelles décrites ci-dessus, Active
                                            Business peut également collecter, utiliser et partager des données agrégées
                                            telles que des données statistiques ou démographiques à quelque fin que ce
                                            soit. Les données agrégées peuvent être dérivées des données personnelles
                                            mais ne sont pas considérées comme des données personnelles en droit car ces
                                            données ne révèlent pas directement ou indirectement votre identité. Par
                                            exemple, pourrons être regroupées les données d'utilisation pour calculer le
                                            pourcentage d'utilisateurs accédant à une fonctionnalité spécifique du site
                                            Web. Si des données agrégées sont combinées ou connectées avec des données
                                            personnelles afin qu'elles puissent identifier l’utilisateur directement ou
                                            indirectement, les données combinées seront traitées comme des données
                                            personnelles et utilisées conformément à la présente politique de
                                            confidentialité.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Données personnelles de catégorie spéciale
                                    <ul>
                                        <li>
                                            Aucune catégorie particulière de données personnelles concernant
                                            l’utilisateur n’est collectée. Cela comprend des détails sur la race,
                                            origine ethnique, croyances religieuses ou philosophiques, données
                                            génétiques et biométriques.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <span className='roboto-bold'>
                            Mentions légales
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                            l’économie numérique, il est précisé dans cet article l’identité des différents intervenants
                            dans le cadre de sa réalisation et de son suivi.
                        </p>
                        <p>
                            Le site www.active-assistance.com est édité par :
                        </p>
                        <p>
                            Active Business Développement<br/>
                            SARL au capital de 30000€<br/>
                            R.C.S. Antibes B504472481<br/>
                            N° TVA : FR 75821070489<br/>
                            Siège social : 92 boulevard Président Wilson. 06160. Antibes Juan les Pins – France
                            <p>
                                Ci-après “l’Éditeur”
                            </p>
                            <p>
                                Contact : contact@active-business.fr
                            </p>
                            <p>
                                Le site urgent-assistance.com est hébergé par :<br/>
                                Infomaniak Network SA<br/>
                                Rue Eugène-Marziano 25, 1227 Genève – Suisse<br/>
                                https://support.infomaniak.com<br/>
                            </p>
                            <p>
                                Sont considérés comme utilisateurs tous les internautes qui naviguent, lisent,
                                visionnent et utilisent le site www.urgent-assistance.com
                            </p>
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <span className='roboto-bold'>
                            À propos
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='p-3'>
                            <p>
                                Urgent Assistance © {new Date().getFullYear()}<br/>
                                {t('messages.distributed')}
                            </p>
                            <p>{t('messages.reserved')}</p>
                            <p>
                                {t('messages.licensed')}<br/>
                                {user.name}<br/>
                                {t('messages.at')} {user.number}
                            </p>
                            <p>
                                {t('messages.advert')} :
                            </p>
                            <p style={{
                                textAlign: 'justify'
                            }}>
                                {t('messages.about1')}
                            </p>
                            <p style={{
                                textAlign: 'justify'
                            }}>
                                {t('messages.about2')}
                            </p>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <Link to='/' className="btn btn-outline-dark outline-grey shadow-lg w-100">{t('buttons.close')}</Link>
            </div>
        </div>
    )
}

export default LegalMentions;