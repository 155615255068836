import React, {ChangeEvent, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {t} from "i18next";
import PhoneInput from "react-phone-number-input";
import {E164Number, isValidPhoneNumber} from "libphonenumber-js";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";
import profile from "../assets/icons/profile.png";
import mail from "../assets/icons/message.png";
import lock from "../assets/icons/lock.png";
import check from "../assets/icons/check.png";

const stripePromise = loadStripe('pk_test_2dWuPsNznAvG6c034IgmNLjn');
const MyAccount = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState<any | undefined>(
        JSON.parse(localStorage.getItem('user') || '[]')
    );
    const [track, setTrack] = useState(parseInt(localStorage.getItem('tracking') as string) || 0)
    const [trackingTime, setTrackingTime] = useState(parseFloat(localStorage.getItem('tracking_time') as string) || 0)
    const [screenTime, setScreenTime] = useState(parseInt(localStorage.getItem('screen_time') as string) || 0)
    const [alarmCount, setAlarmCount] = useState(0)
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordVisible2, setPasswordVisible2] = useState(false);
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const formatHour = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    const [range, setRange] = useState(0.05);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!isPasswordVisible2);
    };

    const handleCheckout = (product: number) => {
        navigate(`/checkout/${product}`)
    }

    const setTracking = (e: ChangeEvent) => {
        const key = e.target as HTMLInputElement
        if (key.checked) {
            setTrack(1)
        } else {
            setTrack(0)
        }

        localStorage.setItem('tracking', key.checked ? '1' : '0')

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: localStorage.getItem('user_key'),
                tracking: key.checked ? 1 : 0
            })
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/set-tracking`, requestOptions)
            .then(response => response.json())
            .then(data => {
            })
            .catch((e) => {
            })
    }

    const handleDataChange = (index: any, e: ChangeEvent) => {
        const key = e.target as HTMLInputElement
        let tmp = {...user}
        tmp[index] = key.value
        setUser(tmp)
        localStorage.setItem('user', JSON.stringify(tmp))
    }

    const updateNumber = (value: any) => {
        let tmp = {...user}
        tmp.number = value
        setUser(tmp)
        localStorage.setItem('user', JSON.stringify(tmp))
    }

    const updateUser = (data: any) => {
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        };

        fetch(`${process.env.REACT_APP_API_URL}/users/${localStorage.getItem('user_key')}`, requestOptions)
            .then(response => response.json())
            .then(data => {
            })
            .catch((e) => {
            })
    }

    const handleSaveNewPassword = () => {
        let tmp = user
        if (password == '') {
            if (isValidPhoneNumber(tmp.number as string)) {
                updateUser(tmp)
                navigate('/')
            }else{
                toast.error('Numéro de téléphone invalide', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            navigate('/')
            return;
        } else {
            if (password !== confirm) {
                toast.error('Le mot de passe n\'est pas identique', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            } else {
                tmp.password = password
                localStorage.setItem('user', JSON.stringify(tmp))
                if (isValidPhoneNumber(tmp.number as string)) {
                    updateUser(tmp)
                    navigate('/')
                }else{
                    toast.error('Numéro de téléphone invalide', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    return;
                }
            }
        }


    }

    const setScreenTimeChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        setScreenTime(parseFloat(target.value))
        setRange(parseFloat(target.value))
        localStorage.setItem('screen_time', target.value)
    }

    const formatTime = (hours: number): string => {
        if (hours < 1) {
            const minutes = Math.round(hours * 60);
            return `${minutes} mn`;
        } else {
            const roundedHours = Math.floor(hours);
            return `${roundedHours} heure${roundedHours > 1 ? "s" : ""}`;
        }
    };

    useEffect(() => {
        if (Object.keys(user).length === 0) {
            navigate('/install');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/alarms/user/${localStorage.getItem('user_key')}`)
            .then(response => response.json())
            .then(data => {
                setAlarmCount(data.count)
            })
            .catch(() => {
                navigate('/install');
            })

        if(screenTime !== null) {
            setRange(screenTime)
        }

    }, []);

    return (
        <div className='container col-12 col-md-4 ps-3 pe-3 h-100 roboto-regular'>
            <div className='card mt-4 p-3'>
                <h3 className=' mb-4 roboto-bold dark-blue'>Mon compte</h3>
                <div className='input-group'>
                <span
                    className="input-group-text mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                    id="basic-addon1">
                    <img src={profile} style={
                        {
                            width: '15px'
                        }
                    }/>
                </span>
                    <input placeholder={t('form-elements.last_first')}
                           type='text'
                           className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                           value={user.name}
                           onChange={e => handleDataChange('name', e)}
                    />
                </div>
                <div className='input-group'>
                <span
                    className="input-group-text mb-4 mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                    id="basic-addon1">
                    <img src={mail} style={
                        {
                            width: '15px'
                        }
                    }/>
                </span>
                    <input placeholder='Email'
                           type='email'
                           className='form-control mb-4 border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                           value={user.email!}
                           onChange={e => handleDataChange('email', e)}
                    />
                </div>
                <PhoneInput
                    international={false}
                    defaultCountry="FR"
                    countries={["FR"]}
                    country="FR"
                    className='form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0 mb-4'
                    placeholder={t('form-elements.number')}
                    value={user.number}
                    onChange={e => updateNumber(e)}/>
                <div className="input-group mb-4">
                <span
                    className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                    id="basic-addon1">
                            <img src={lock} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                    <input placeholder='Mot de passe'
                           type={isPasswordVisible ? 'text' : 'password'}
                           className='form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                           value={password}
                           onChange={(event) => setPassword(event.target.value)}
                    />
                    <span
                        className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                        onClick={togglePasswordVisibility}
                        style={{cursor: 'pointer'}}>
                            {isPasswordVisible ? <i className='fa fa-eye color-grey'></i> :
                                <i className='fa fa-eye-slash color-grey'></i>}
                          </span>
                </div>
                <div className="input-group mb-4">
                <span
                    className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                    id="basic-addon1">
                            <img src={lock} style={
                                {
                                    width: '15px'
                                }
                            }/>
                        </span>
                    <input placeholder='Confirmation de mot de passe'
                           type={isPasswordVisible2 ? 'text' : 'password'}
                           className='form-control border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0'
                           value={confirm}
                           onChange={(event) => setConfirm(event.target.value)}
                    />
                    <span
                        className="input-group-text border-top-0 bg-white border-start-0 border-end-0 rounded-bottom-0"
                        onClick={togglePasswordVisibility2}
                        style={{cursor: 'pointer'}}>
                            {isPasswordVisible2 ? <i className='fa fa-eye color-grey'></i> :
                                <i className='fa fa-eye-slash color-grey'></i>}
                          </span>
                </div>
            </div>
            <div className='card mt-3 p-3'>
                <h4 className='roboto-bold dark-blue'>Géolocalisation GPS<br/>suivie en temps réel (Tracking)</h4>
                <div className='mt-3'>
                    <div className='row'>
                        <div className='col-6 fs-4'>
                            Autoriser
                        </div>
                        <div className='col-6'>
                            <Form.Check
                                className='float-end'
                                type="switch"
                                id="custom-switch"
                                checked={track === 1}
                                onChange={setTracking}
                                style={{
                                    transform: "scale(1.8)"
                                }}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 fs-4'>
                            Durée du tracking
                        </div>
                        <div className='col-12'>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                       id="inlineRadio1" checked={trackingTime === 0.30} onChange={event => {
                                    setTrackingTime(0.30)
                                    localStorage.setItem('tracking_time', '0.30')
                                }}/>
                                <label className="form-check-label" htmlFor="inlineRadio1">30 minutes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                       id="inlineRadio2" checked={trackingTime === 1} onChange={event => {
                                    setTrackingTime(1)
                                    localStorage.setItem('tracking_time', '1')
                                }}/>
                                <label className="form-check-label" htmlFor="inlineRadio2">1 heure</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                       id="inlineRadio3" checked={trackingTime === 3} onChange={event => {
                                    setTrackingTime(3)
                                    localStorage.setItem('tracking_time', '3')
                                }}/>
                                <label className="form-check-label" htmlFor="inlineRadio3">3 heures</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mt-3 p-3'>
                <h4 className='roboto-bold dark-blue'>Affichage permanent <i>(voir Guide pratique)</i></h4>
                <div className='col-12'>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2"
                               id="inlineRadio1" checked={screenTime === 0.30} onChange={event => {
                            setScreenTime(0.30)
                            localStorage.setItem('screen_time', '0.30')
                        }}/>
                        <label className="form-check-label" htmlFor="inlineRadio1">30 minutes</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2"
                               id="inlineRadio2" checked={screenTime === 1} onChange={event => {
                            setScreenTime(1)
                            localStorage.setItem('screen_time', '1')
                        }}/>
                        <label className="form-check-label" htmlFor="inlineRadio2">1 heures</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2"
                               id="inlineRadio3" checked={screenTime === 3} onChange={event => {
                            setScreenTime(3)
                            localStorage.setItem('screen_time', '3')
                        }}/>
                        <label className="form-check-label" htmlFor="inlineRadio3">3 heures</label>
                    </div>
                </div>
                <div className='mt-2'>
                    Personnalisé de 5 mn à 12 heures : {formatTime(range)}
                </div>
                <div>
                    <input type="range" onChange={event => setScreenTimeChange(event)}
                           className="form-range slider"
                           min="0.08"
                           max="13"
                           step='0.1' id="customRange1"/>
                </div>
            </div>
            <div className='card mt-3 p-3'>
                <h4 className='roboto-bold dark-blue'>Solde SMS maintenant :
                    <span
                        className='text-blue'> {String(alarmCount).padStart(2, '0')}</span>
                </h4>
                <h4>
                    le {formattedDate} à {formatHour}
                </h4>
            </div>
            <div className='card mt-3 p-3 mb-5'>
                <h4 className='my-3 mt-5 roboto-bold dark-blue'>Recharger mon Pack SMS SOS</h4>
                <div className='row text-center mb-5'>
                    <div className='col-6'>
                        <button className='btn btn-danger' onClick={e => handleCheckout(1)}>Pack 3 SMS</button>
                    </div>
                    <div className='col-6'>
                        <button className='btn btn-outline-danger' onClick={e => handleCheckout(2)}>Pack 10 SMS</button>
                    </div>
                </div>
            </div>
            <div className='fixed-bottom bottom-0 bg-white text-center px-5 pt-3' style={
                {
                    height: '75px'
                }
            }>
                <div className='row'>
                    <div className='col-6'>
                        <Link to='/' className='btn btn-outline-dark outline-grey shadow-lg w-100'>Fermer</Link>
                    </div>
                    <div className='col-6'>
                        <button className='btn btn-primary bg-dark-blue text-white w-100 dark-blue-border'
                                onClick={handleSaveNewPassword}>Valider
                        </button>
                    </div>
                </div>
            </div>
            <style>
                {`
                body {
                    background-color: #f7fafd;
                }
                `}
            </style>
        </div>
    )
}

export default MyAccount;